import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { StyleSheet, css } from 'aphrodite'

import AlternativeLayout from '../layouts/AltertineLayout'
import Classes from '../../styles/classes'

import PageLink from '../Link'
import pagesInfos from '../../config/i18n/pagesInfos'

export default ({ data }) => {
  const article = data.article

  const breadcrumbLinks = [
    <PageLink page={pagesInfos.ComponentBlog}>Blog</PageLink>,
    <Link
      to={article.permalink}
      title={article.titre}
      className={css(Classes.link)}
    >
      {article.titre}
    </Link>
  ]

  const Tags = article.tags.map(tag => (
    <li className={css(Classes.link)}>
      <a href="#">{tag}</a>
    </li>
  ))

  return (
    <AlternativeLayout
      title={article.titre}
      subtitle={'article.description.description'}
      image={article.image.resolutions.src}
      contact
      breadcrumbLinks={breadcrumbLinks}
    >
      <section className="blog blog-single section-padding">
        <div className="container">
          <div className="row">
            <div className="row-center col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <div className="row-left clearfix">
                <div className="single-blog-text">
                  <div className="blog-text-content">
                    <ReactMarkdown source={article.contenu.contenu} />
                    <div className="blog-single-footer">
                      <div className="tags">
                        <ul>{Tags}</ul>
                      </div>

                      <div className="social">
                        <ul>
                          <li>
                            <a href="">
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>
                          <li>
                            <a href="">
                              <i className="fab fa-behance" />
                            </a>
                          </li>
                          <li>
                            <a href="">
                              <i className="fab fa-youtube" />
                            </a>
                          </li>
                          <li>
                            <a href="">
                              <i className="fab fa-dribbble" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AlternativeLayout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    article: contentfulArticles(id: { eq: $id }) {
      id
      titre
      tags
      permalink
      contenu {
        contenu
      }
      image {
        resolutions(width: 1600) {
          width
          height
          src
          srcSet
        }
      }
    }
  }
`
